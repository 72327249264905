<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="处理状态" prop="waitorder">
              <a-select v-model="queryParams.waitorder" style="width:140px">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">待处理</a-select-option>
                <a-select-option :value="1">已完成</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="接警人员" prop="processperson">
              <a-input v-model.trim="queryParams.processperson" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="alarmid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="detail" slot-scope="value, record">
            <a-button type="link" size="small" @click="detail(value,record)">详情</a-button>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" size="small" @click="deleteData(value,record)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <otherBack :visible.sync="otherVisible" ref="other" :OtherData="otherData"></otherBack>
  </page-container>
</template>

<script>
import { getItemFromArrayByKey, getTimeRange } from 'U'
import moment from 'moment'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import { getMentenacegroupListByCondition } from 'A/xtpz.js'
import { getLiftinfoListByCondition, deleteLiftinfo, getLiftinfoById } from 'A/jcgn.js'
import {deleteAlarmFiles, getAlarmessageListByCondition, getAlarmFilesListByCondition} from "A/thjlcx";
import otherBack from "V/yjcz/emhistory/otherBack";
import callCenter from "C/modals/callCenter";

export default {
  name: 'otherhistory',
  mixins: [areaselect, deptselect, pagination],
  props: ['pageType'],
  components: {
    otherBack,
  },
  data() {
    return {
      moment,
      otherVisible:false,
      otherData:'',
      showAdvanced: false,
      queryParams: {
        liftrescue: '',
        caller:'',
        callertel:'',
        called:'',
        calltime:'',
        message:'',
        waitorder:'',
        processperson:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      tableColumns: [
        {
          title: '接警人员',
          dataIndex: 'processperson',
          key: 'processperson',
          ellipsis: true,
        },
        {
          title: '报警人员',
          dataIndex: 'alarmcaller',
          key: 'alarmcaller',
          ellipsis: true,
        },
        {
          title: '来电号码',
          dataIndex: 'callertel',
          key: 'callertel',
          ellipsis: true,
        },
        {
          title: '接警时间',
          dataIndex: 'calledtime',
          key: 'calledtime',
          ellipsis: true,
          scopedSlots: { customRender: 'time' }
        },
        {
          title: '报警信息',
          dataIndex: 'message',
          key: 'message',
          ellipsis: true,
        },
        {
          title: '电梯救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
        },
        {
          title: '报警类型',
          dataIndex: 'alarmtype',
          key: 'alarmtype',
          ellipsis: true,
        },
        {
          title: '处理状态',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
        },
        {
          title: '详情',
          key: 'detail',
          align: 'center',
          width: 50,
          scopedSlots: { customRender: 'detail' }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      counts:'',
    }
  },
  computed:{
    pageTitle() {
      return '打错电话历史';
    },
    breadcrumb() {
      return [
        {
          name: '应急处置',
          path: ''
        },
        {
          name: '应急救援历史',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        // ...this.queryParams,
        alarmtype:'4',
        waitorder:this.queryParams.waitorder,
        processperson:this.queryParams.processperson,
        endflag:'2',
        endding:'1',
        ordertype:'1',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getAlarmFilesListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
          const list = this.tableData.filter(item => item.alarmtype == 4)
          for (var i = 0; i < res.item.length; i++) {
              this.tableData[i].alarmtype = "打错电话"
            if (res.item[i].waitorder == '0') {
              this.tableData[i].status = "已受理"
            } else if (res.item[i].waitorder == '1') {
              this.tableData[i].status = "已完成"
            }
          }
          this.tableData = list
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    detail(value,record){
      this.otherData=record;
      this.otherVisible=true;
    },
    deleteData(record,value){
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.alarmid);
        },
        onCancel() {},
      });
    },
    delete(id) {
      deleteAlarmFiles({alarmid: id}).then(res => {
        if (res && res.returncode == '0') {
          this.getTableData(true)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>